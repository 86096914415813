<template>
  <form
    ref="formRef"
    :action="payment.action"
    method="POST"
    name="dotpay_redirection_form"
    accept-charset="UTF-8"
  >
    <input name="api_version" :value="payment.api_version" type="hidden" />
    <input name="amount" :value="payment.amount" type="hidden" />
    <input name="currency" :value="payment.currency" type="hidden" />
    <input name="description" :value="payment.description" type="hidden" />
    <input name="url" :value="payment.url" type="hidden" />
    <input name="type" :value="payment.type" type="hidden" />
    <input name="buttontext" :value="payment.buttontext" type="hidden" />
    <input name="urlc" :value="payment.urlc" type="hidden" />
    <input name="control" :value="payment.control" type="hidden" />
    <input name="firstname" :value="payment.firstname" type="hidden" />
    <input name="lastname" :value="payment.lastname" type="hidden" />
    <input name="email" :value="payment.email" type="hidden" />
    <input
      name="ignore_last_payment_channel"
      :value="payment.ignore_last_payment_channel"
      type="hidden"
    />
    <input name="channel_groups" :value="payment.channel_groups" type="hidden" />
    <input name="id" :value="payment.id" type="hidden" />
    <input name="chk" :value="payment.chk" type="hidden" />
  </form>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import axios from 'axios'
import { api } from '@/config'

let payment = ref({
  action: '',
  url: '',
  api_version: '',
  amount: '',
  currency: '',
  description: '',
  type: '',
  buttontext: '',
  urlc: '',
  control: '',
  firstname: '',
  lastname: '',
  email: '',
  ignore_last_payment_channel: '',
  channel_groups: ''
})

const formRef = ref(null)

async function pay() {
  // Przelewy24:
  // await axios
  //   .post(api.path('payments.payP24'))
  //   .then(response => {
  //       const gatewayUrl = response.data.gateway_url;
  //       window.location.href = gatewayUrl;
  //   })
  //   .catch(error => {
  //       console.error('Błąd podczas inicjowania płatności:', error);
  //   });

  // Przelewy24 (DotPay API):  
  formRef.value.submit()
}

onMounted(async () => {
  const { data } = await axios.get(api.path('payments.params'))
  payment.value = data.ParametersArray
})

defineExpose({
  pay
})
</script>
