<template>
  <v-dialog v-model="showExamRules" max-width="750px">
    <exam-rules @dialog="showExamRules = $event"></exam-rules>
  </v-dialog>

  <pay-form ref="payFormRef"></pay-form>

  <v-navigation-drawer v-model="drawer" :rail="rail" width="280" :permanent="$vuetify.display.mdAndUp">
    <v-list class="py-0">
      <v-list-item :title="name">
        <template v-slot:append>
          <v-btn variant="text" :icon="rail ? 'mdi-chevron-right' : 'mdi-chevron-left'" @click.stop="rail = !rail"></v-btn>
        </template>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>

    <v-list class="py-0" dense v-for="(item, index) in items" :key="index">
      <v-list-item
        @click="item.action ? item.action() : false"
        href="javascript:void(0)"
        :to="item.to"
        ripple
        :exact="item.exact !== undefined ? item.exact : true"
        :prepend-icon="item.icon"
        :title="item.title"
        :disabled="item.disabled"
      >
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import PayForm from '@/components/ui/PayForm.vue'
import ExamRules from '@/components/shared/RegisterRules.vue'

import { ref, onMounted } from 'vue'

import { useAuthUserStore } from '@/stores/auth-user'
const authUserStore = useAuthUserStore()

import { settings } from '@/config'
const isPaymentDisabled = settings.isPaymentDisabled
const isEditDisabled = settings.isEditDisabled

const name = ref()
const drawer = ref(true)
const rail = ref(false)
const items = ref([])
const showExamRules = ref(false)

function navigation() {
  if (authUserStore.isUser) {
    items.value = [
      {
        title: 'Profil',
        icon: 'mdi-account-outline',
        to: { name: 'user-profile' },
        exact: true
      },
      {
        title: 'Uzupełnij/Popraw dane',
        icon: 'mdi-account-edit-outline',
        to: { name: 'profile-edit' },
        exact: true,
        disabled: isEditDisabled
      },
      {
        title: 'Zmień hasło',
        icon: 'mdi-textbox-password',
        to: { name: 'password-edit' },
        exact: true
      },
      {
        title: 'Komunikator',
        icon: 'mdi-message-outline',
        to: { name: 'user-chat' },
        exact: true
      },
      {
        title: 'Zapłać',
        icon: 'mdi-credit-card-outline',
        exact: true,
        action: pay,
        disabled: isPaymentDisabled || !!authUserStore.user.paid_at || !authUserStore.user.parent_last_name
      },
      {
        title: 'Zasady egzaminów',
        icon: 'mdi-information-outline',
        action: () => {
          showExamRules.value = true
        },
        exact: true,
        disabled: false
      },
      {
        title: 'Zakończ',
        icon: 'mdi-logout',
        action: logout
      }
    ]
  } else if (authUserStore.isAdmin) {
    items.value = [
      {
        title: 'Lista kandydatów',
        icon: 'mdi-account-multiple',
        to: { name: 'admin-users' }
      },

      {
        title: 'Komisje egzaminacyjne',
        icon: 'mdi-calendar-account-outline',
        to: { name: 'admin-exams' }
      },
      {
        title: 'Wyniki egzaminów',
        icon: 'mdi-help-rhombus-outline',
        to: { name: 'admin-marks' }
      },
      {
        title: 'Wyniki ankiety',
        icon: 'mdi-forum-outline',
        to: { name: 'admin-surveys' }
      },
      // {
      //   title: 'Wyniki rekrutacji',
      //   icon: 'mdi-forum-outline',
      //   to: { name: 'admin-results' }
      // },
      // {
      //   title: 'Odpowiedzi uczniów',
      //   icon: 'mdi-forum-outline',
      //   to: { name: 'admin-answers' }
      // },
      {
        title: 'Parametry',
        icon: 'mdi-tools',
        to: { name: 'admin-params' }
      },
      {
        title: 'Ustawienia/Testy',
        icon: 'mdi-checkbox-blank-outline',
        to: { name: 'admin-tools' }
      },
      {
        title: 'Zmień hasło',
        icon: 'mdi-textbox-password',
        to: { name: 'admin-password-edit' },
        exact: true
      },
      {
        title: 'Zakończ',
        icon: 'mdi-logout',
        action: logout
      }
    ]
  } else if (authUserStore.isTeacher) {
    items.value = [
      {
        title: 'Wyniki egzaminów',
        icon: 'mdi-account-multiple',
        to: { name: 'teacher-dashboard' }
      },

      {
        title: 'Zmień hasło',
        icon: 'mdi-textbox-password',
        to: { name: 'teacher-password-edit' },
        exact: true
      },
      {
        title: 'Zakończ',
        icon: 'mdi-logout',
        action: logout
      }
    ]
  } else {
    items.value = []
  }
}

const emit = defineEmits(['logout'])

function logout() {
  emit('logout')
}

const payFormRef = ref(null)
function pay() {
  payFormRef.value.pay()
}

onMounted(async () => {
  name.value = `${authUserStore.user.first_name} ${authUserStore.user.last_name}`
  navigation()
})
</script>
