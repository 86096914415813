import WelcomePage from '@/components/guest/WelcomePage.vue'
import LoginPage from '@/components/guest/login/LoginPage.vue'
import RegisterPage from '@/components/guest/register/RegisterPage.vue'
import UserWrapper from '@/components/user/UserWrapper.vue'
import ProfilePage from '@/components/user/profile/ProfilePage.vue'

function applyRules(rules, routes) {
  for (let i in routes) {
    routes[i].meta = routes[i].meta || {}

    if (!routes[i].meta.rules) {
      routes[i].meta.rules = []
    }
    routes[i].meta.rules.unshift(...rules)

    if (routes[i].children) {
      routes[i].children = applyRules(rules, routes[i].children)
    }
  }
  return routes
}

export default 

/*
[
  { path: '', name: 'guest-index', redirect: { name: 'welcome' } },
  { path: '/', name: 'welcome', component: WelcomePage },
  { path: '/login', name: 'login', component: LoginPage },
  { path: '/user', component: UserWrapper, children: [{ path: 'profile', name: 'user-profile', component: ProfilePage }] }
]
*/

[
  ...applyRules(
    ['guest_u', 'guest_a', 'guest_t'],
    [
      { path: '', name: 'guest-index', redirect: { name: 'welcome' } },
      { path: '/', name: 'welcome', component: WelcomePage },
      {
        path: '/login',
        name: 'login',
        component: LoginPage
      },
      {
        path: '/register',
        name: 'register',
        component: RegisterPage
      },
      {
        path: '/activate',
        name: 'activate',
        component: () => import('@/components/guest/activate/ActivatePage.vue')
      },
      {
        path: '/password-forgot',
        name: 'password-forgot',
        component: () => import('@/components/guest/password-forgot/PasswordForgot.vue')
      },
      {
        path: '/password/reset/:token',
        name: 'reset',
        component: () => import('@/components/guest/password/password-reset/PasswordReset.vue')
      }
    ]
  ),
  ...applyRules(
    ['user_g', 'user_a', 'user_t'],
    [
      {
        path: '/user',
        component: UserWrapper,

        children: [
          {
            path: '',
            name: 'user-dashboard',
            redirect: { name: 'user-profile' }
          },
          {
            path: 'profile',
            name: 'user-profile',
            component: ProfilePage
          },
          {
            path: 'chat',
            name: 'user-chat',
            component: () => import('@/components/user/profile/ChatWrapper.vue')
          },
          {
            path: 'profile-edit',
            name: 'profile-edit',
            component: () => import('@/components/user/edit/ProfileEdit.vue')
          },
          {
            path: 'passedit',
            name: 'password-edit',
            component: () => import('@/components/user/edit/PasswordEdit.vue')
          },
          {
            path: 'payments-return',
            name: 'profile-payments-return',
            component: () => import('@/components/user/payments/ReturnPage.vue')
          },
          {
            path: '/answer-group-exam',
            name: 'answer-group-exam',
            component: () => import('@/components/user/exams/AnswerGroupExam.vue')
          }
        ]
      }
    ]
  ),
  
  ...applyRules(
    ['admin_g', 'admin_u', 'admin_t'],
    [
      {
        path: '/admin',
        component: () => import('@/components/admin/AdminWrapper.vue'),

        children: [
          {
            path: 'admin',
            name: 'admin-dashboard',
            redirect: { name: 'admin-users' }
          },
          {
            path: 'users',
            name: 'admin-users',
            component: () => import('@/components/admin/UsersList.vue')
          },
          {
            path: 'exams',
            name: 'admin-exams',
            component: () => import('@/components/admin/ExamsList.vue')
          },
          {
            path: 'marks',
            name: 'admin-marks',
            component: () => import('@/components/admin/MarksList.vue')
          },
          {
            path: 'surveys',
            name: 'admin-surveys',
            component: () => import('@/components/admin/Surveys.vue')
          },
          {
            path: 'results',
            name: 'admin-results',
            component: () => import('@/components/admin/ResultsList.vue')
          },
          {
            path: 'answers',
            name: 'admin-answers',
            component: () => import('@/components/admin/AnswersList.vue')
          },
          {
            path: 'params',
            name: 'admin-params',
            component: () => import('@/components/admin/AppParams.vue')
          },
          {
            path: 'tools',
            name: 'admin-tools',
            component: () => import('@/components/admin/AppTools.vue')
          },
          {
            path: 'payments-return',
            name: 'profile-payments-return',
            component: () => import('@/components/admin/payments/ReturnPage.vue')
          },
          {
            path: 'passedit',
            name: 'admin-password-edit',
            component: () => import('@/components/admin/PasswordEdit.vue')
          }
        ]
      }
    ]
  ),
  ...applyRules(
    ['teacher_g', 'teacher_u', 'teacher_a'],
    [
      {
        path: '/teacher',
        component: () => import('@/components/teacher/TeacherWrapper.vue'),

        children: [
          {
            path: 'dashboard',
            name: 'teacher-dashboard',
            component: () => import('@/components/teacher/MainScreen.vue')
          },
          {
            path: 'marks/:examType',
            name: 'teacher-marks',
            component: () => import('@/components/teacher/Marks.vue'),
            props: true
          },
          {
            path: 'passedit',
            name: 'teacher-password-edit',
            component: () => import('@/components/teacher/PasswordEdit.vue')
          }
        ]
      }
    ]
  ),
  { path: '/:pathMatch(.*)', redirect: { name: 'welcome' } }
]

