<template>
  <v-sheet border>
    <v-container>
      <h3 class="mb-4">Drogie Kandydatki, Drodzy Kandydaci, Szanowni Państwo,</h3>

      <div class="text-primary font-weight-bold mt-4">
        <div class="text-error">Bardzo prosimy:</div>
        - sprawdź na swoim koncie, którego dnia i o której godzinie zostało wyznaczone Twoje zadanie zespołowe<br>
        - sprawdź miejsce swojego egzaminu pisemnego, bo nie wszystkie osoby piszą w budynku naszej szkoły!<br>
        - sprawdź dzień i godzinę rozmowy i znajdź sposób, by o niej nie zapomnieć.<br><br>
        <div class="text-error">Uwaga!</div>
        W przypadku jakichkolwiek problemów, wątpliwości, zdarzeń losowych lub choroby, prosimy niezwłocznie powiadamiać nas przez
        komunikator.<br>
        Kandydatki i kandydaci zakończą zadanie zespołowe po około 3 godzinach przed budynkiem szkoły przy ul. Kawalerii 5.
      </div>

      <!-- <h3 class="mb-4 text-error">Uwaga,</h3>
      <div class="text-primary font-weight-bold mt-4 mb-2">
        Uprzejmie informujemy, że wyniki rekrutacji ogłosimy w piątek 19 kwietnia do godziny 12.00. <br />Będą dostępne po zalogowaniu się do systemu
      </div> -->

    </v-container>
  </v-sheet>
</template>

<script setup></script>
