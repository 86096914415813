<template>
  <v-row justify="center">
    <v-col cols="12" md="12" lg="10" xl="8">
      <div v-if="applyState == 2" class="mt-8 red--text text--darken-4">
        <h3>Rejestracja została zakończona 24 lutego o godz. 24:00.</h3>
      </div>

      <v-sheet v-if="applyState == 1 || !isRegisteredDisabled" border class="pa-5">
        <h2>Rejestracja kandydata/kandydatki</h2>

        <div v-if="!registered && !susspended" class="text-subtitle-1 mt-4">
          <p class="mb-4">
            Szanowni Państwo, Drogie Kandydatki i Kandydaci,<br />
            Bardzo prosimy o staranne upewnienie się przez Kandydatkę/Kandydata i Rodziców/Opiekunów, że świadomie wybierają zdawanie do naszej
            szkoły, czyli <b>Bednarskiej Szkoły Realnej</b>, mieszczącej się przy ul. Kawalerii 5 w pobliżu Łazienek. Każdego roku zdarzają się w czasie
            zapisów kłopotliwe pomyłki! Linki do wszystkich szkół Zespołu „Bednarska” znajdują się tutaj:
            <a href="https://tpbednarska.edu.pl/" target=_blank>https://tpbednarska.edu.pl/</a> <br />
            <strong>Gorąco zachęcamy do zajrzenia na strony szkół i sprawdzenia, czy zapisujecie się do tej wymarzonej!</strong>
          </p>
          <p>
            Rejestracja odbywa się w dwóch krokach. W pierwszym kroku należy podać podstawowe dane o kandydacie/tce oraz adres mailowy, na który będą
            przesyłane wszystkie wiadomości od systemu. Adres nie jest udostępniany publicznie i będzie wykorzystywany jedynie do komunikacji z
            Bednarską Szkołą Realną w procesie rekrutacji.
          </p>
          <p>
            Po rejestracji na podany adres mailowy zostanie wysłany link aktywacyjny. Po aktywacji kandydat/tka dostanie swój
            <strong>login</strong>, za pomocą którego będzie mógł/mogła się logować do systemu.
          </p>

          <register-form @success="success"></register-form>
        </div>

        <div v-if="registered" class="text-subtitle-1 mt-4">
          <p>
            Kandydat/tka został/a wstępnie zarejestrowany/a. Na adres mailowy
            <strong>{{ user_email }}</strong> został wysłany mail z linkiem aktywacyjnym.
          </p>
          <p>
            Jeśli w ciągu kilku minut nie dostałeś/łaś maila, proszę sprawdź w swojej poczcie katalog ze spamem oraz inne istniejące katalogi (np.
            promocje, powiadomienia, itp.)
          </p>

          <div class="mt-4">
            <v-btn color="primary" variant="text" to="/">Powrót</v-btn>
          </div>
        </div>

        <div v-if="susspended" class="text-subtitle-1 mt-4">
          <p class="text-red-darken-4 mb-2">
            <b>Proces rejestracji kandydata/tki został wstrzymany ze względów formalnych - zadeklarowałeś/łaś, że nie jesteś ósmoklasistą/tką.</b>
          </p>
          <p>
            W celu odblokowania rejestracji prosimy o wysłanie na adres sekretariatu szkoły <strong>sekretariat@bsr.edu.pl</strong>, krótkiego
            wyjaśnienia, dotyczącego obecnej sytuacji edukacyjnej kandydata/tki.
          </p>

          <div class="mt-4">
            <v-btn color="primary" variant="text" to="/">Powrót</v-btn>
          </div>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { settings } from '@/config'
import RegisterForm from './RegisterForm.vue'

export default {
  components: {
    RegisterForm
  },

  data: () => ({
    registered: false,
    susspended: false,
    user_email: null,
    applyState: settings.applyState,
    isRegisteredDisabled: settings.applyState
  }),

  methods: {
    success(data) {
      if (data.susspended) {
        this.susspended = true
      } else {
        this.user_email = data.user.email
        this.registered = true
      }
    }
  }
}
</script>
