const { apiUrl } = window.app

export const settings = {
  siteName: window.app.siteName,
  siteUrl: window.app.siteUrl,
  applyState: 2, // 0-przed, 1-ok, 2-koniec
  isRegisteredDisabled: true,
  isEditDisabled: true,
  isPaymentDisabled: true,
}

/*
const apiUrl = import.meta.env.BASE_URL + '\\api'

export const settings = {
  siteName: 'Rekrutacja do BSR',
  siteUrl: import.meta.env.BASE_URL,
  applyState: 1 // 0-przed, 1-ok, 2-koniec
}

console.log(import.meta.env.BASE_URL)
*/

class URL {
  constructor(base) {
    this.base = base
  }

  path(path, args) {
    path = path.split('.')
    let obj = this
    let url = this.base

    for (let i = 0; i < path.length && obj; i += 1) {
      if (obj.url) {
        url += `/${obj.url}`
      }

      obj = obj[path[i]]
    }
    if (obj) {
      url = `${url}/${typeof obj === 'string' ? obj : obj.url}`
    }

    if (args) {
      for (const key in args) {
        url = url.replace(':' + key, args[key])
      }
    }

    return url
  }
}

export const api = Object.assign(new URL(apiUrl), {
  url: '',

  login: {
    url: 'login',
    refresh: 'refresh'
  },

  logout: 'logout',

  register: 'register',

  activate: 'activate',

  password: {
    url: 'password',

    forgot: 'email',
    reset: 'reset'
  },

  me: 'me',

  users: {
    url: 'users',

    index: '',
    update: ':id',
    destroy: ':id',

    activate: ':id/activate',

    confirmExamsRules: ':id/confirm-exams-rules',
    confirmExamsTimes: ':id/confirm-exams-times',
    confirmOnline: ':id/confirm-online',
    confirmGroupExam: ':id/confirm-group-exam',
    confirmOralExam: ':id/confirm-oral-exam',
    confirmTestExams: ':id/confirm-test-exams',

    single: ':id',
    restore: ':id/restore'
  },

  profile: {
    url: 'profile',

    password: 'change-password',
    files: 'files/:id'
  },

  exams: {
    url: 'exams',

    index: '',
    free: 'free',
    user: ':id',
    update: ''
  },

  freeExams1: {
    url: 'free-exams-1'
  },
  freeExams: {
    url: 'free-exams'
  },

  messages: {
    url: 'messages',

    store: '',
    index: ':id',
    update: ':id'
  },

  marks: {
    url: 'marks',

    index: ':teacher_id/:exam_type_id',
    store: '',
    update: ':id',
    destroy: ':id'
  },

  answers: {
    url: 'answers',

    index: ':user_id',
    store: '',
    update: ':id',
    destroy: ':id'
  },

  results: {
    url: 'results',

    index: ':user_id',
    store: '',
    update: ':id',
    destroy: ':id',
  },

  survey: {
    url: 'survey',

    results: 'results/combined',
  },

  payments: {
    url: 'payments',

    return: 'return',
    params: 'params',
    payP24: 'payP24',
    testP24: 'testP24'
  },

  schools: 'schools',

  export: {
    url: 'export',

    users: 'users',
    examsPdf: 'exams/pdf',
    examsCsv: 'exams/csv',
    examCard: 'exam-cards/:id',
    marks: 'marks',
    answers: 'answers'
  },

  files: {
    url: 'files',

    user: 'user/:file'
  },

  config: {
    url: 'config',

    store: '',
    counter: 'counter'
  }
})
